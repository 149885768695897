import React from "react"
import { Link, navigate } from "gatsby"

import { Navbar, Nav } from "react-bootstrap"
import { auth, useAuth } from "gatsby-theme-firebase";

const CustomNavbar = ({ pageInfo }) => {
  const { isAuthLoading, isLoggedIn, profile } = useAuth();

  console.log(pageInfo)
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar">
        {/* <Container> */}
        <Link to="/" className="link-no-style">
          <Navbar.Brand as="span">Dodgers Team Travel</Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" activeKey={pageInfo && pageInfo.pageName}>
            <Nav.Item>
              <Link to="/admin" className="link-no-style">
                <Nav.Link as="span" eventKey="page-2">
                  Admin
                </Nav.Link>
              </Link>
            </Nav.Item>
            {isLoggedIn && 
              <Nav.Item >
                <a 
                  href="/" 
                  onClick={event => {
                    event.preventDefault()
                    auth.signOut()
                    navigate('/')
                  }} 
                  className="link-no-style" >
                  <Nav.Link as="span" >
                    Sign Out
                  </Nav.Link>
                </a> 
              </Nav.Item>}
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default CustomNavbar
